.ionContent {
  --ion-background-color: var(--weaver-light-grey);
}

.noResultsContainer {
  margin: 1rem 0;

  ion-label {
    font-weight: 600;
    padding-inline-start: 1.5rem;
  }

  & + & {
    margin-top: 10rem;
  }
}

.card {
  --background: var(--theme-white);

  border-radius: 1em;

  --color: var(--ion-text-color);

  max-width: 40rem;

  p {
    font-size: 0.9rem;
  }
}

.projectsIcon {
  width: 3rem;
}
