.ionToolbar {
  --background: transparent;
}

.ionFooter {
  background-color: var(--ion-background-color);
}

.errorMessage {
  text-align: end;
  margin: 0 0 0.2rem;
  font-size: 0.8rem;
  color: var(--ion-color-danger);
}
