.projectCard {
  h1,
  h2,
  h3 {
    color: var(--ion-color-light-contrast);
  }
}

.projectMemberItem {
  ion-label {
    h2 {
      font-size: 1.2rem;
    }

    h3 {
      color: var(--ion-color-step-600, var(--ion-text-color));
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 0.025rem;
      text-transform: uppercase;
    }
  }
}

.chatIconButton {
  border: 0;
  margin: 0;
}

.memberActions {
  display: flex;
  justify-content: flex-end;
}

.projectDocumentCard {
  margin-left: 0;
  margin-right: 0;
}

.iconDateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadIcon {
  font-size: 1.3rem;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
}

.downloadSpinner {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-width: 1rem;
  max-height: 1.3rem;
}

.modalContainer {
  padding: 0 1rem 2rem;
}
