.buttonIcon {
  margin-right: 0.5rem;
}

.ionItem {
  --ion-item-background: var(--ion-color-medium-contrast);
}

.cursorEnabled {
  cursor: pointer;
}

.displayContractorProfileContainer {
  p {
    margin: 0;
  }

  ion-list {
    margin-top: 0;
    padding-top: 0;
  }

  .companyInfoContainer {
    background-color: var(--ion-color-medium-contrast);
    padding: 0 1rem;
    border-radius: 0 0 0.3rem 0.3rem;

    p {
      color: var(--ion-color-light-grey);
    }

    svg {
      margin-top: 0.5em;
      margin-right: 1em;
    }

    ion-icon {
      color: var(--theme-light-blue);
      height: 0.8em;
      margin-left: 0.5em;
    }

    .companyTradingName {
      margin-bottom: 0;
      max-width: 70%;
    }

    .contractorTeamType {
      margin: 0.5em 0;
    }
  }
}

.unlockedBudgetRangesContainer {
  display: flex;
  flex-direction: column;

  h3 {
    margin-top: 0;
  }

  ion-button {
    margin-top: 1em;
    max-width: 18em;
  }
}

@media only screen and (max-width: 768px) {
  .displayContractorProfileContainer {
    section {
      padding: 0 1em 0.5em;
    }
  }
}
