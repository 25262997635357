.chatRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 4.5rem;
  padding-left: 1rem;
}

.chatRow ion-item {
  width: 100%;
}

.chatRowAvatar {
  height: 3rem;
  width: 3rem;
}

.chatLastMessagePreview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chatContentContainer {
  --background: var(--weaver-background-color);
}

.chatTopContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  align-items: space-between;
}

.chatName {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chatDate {
  font-size: 0.8rem;
  width: 5rem;
  padding-right: 0.5rem;
}

.chatDate::first-letter {
  text-transform: capitalize;
}

.myChatsContentContainer {
  @media only screen and (min-width: 768px) {
    :first-child {
      max-width: 50vw;
      margin: 0 auto;
    }
  }
}
